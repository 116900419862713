<template>
    <div>
        <card-header title="Edit Trip Add-On" icon="fa-pencil"/>

        <card-body>
            <addon-form :addon="addon"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteAddon"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Add-On?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Add-On</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SearchListItem from "@/TIER/components/SearchListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";
    import TripListItem from "@/components/TripListItem";

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import DataRow from "@/TIER/components/DataRow";
    import DayListItem from "@/components/DayListItem";
    import EventListItem from "@/components/EventListItem";
    import ContainerListItem from "@/TIER/components/ContainerListItem";
    import AddonForm from "./AddonForm";
    import CardFooter from "@/TIER/components/CardFooter";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {CardFooter, AddonForm, ContainerListItem, EventListItem, DayListItem, DataRow, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                addon: {},
                trip: {},
                confirmDelete: false,
                dirty: null
            }
        },
        methods: {
            loadAddon: function() {
                this.$emit('loading', true);
                http.get('/api/addons/' + this.props.addonId, {force: true}).then(response => {
                    this.addon = response.data;
                    this.$emit('loading', false);
                });
            },
            submit: function() {
                this.$emit('loading', true);
                http.put('/api/addons/' + this.props.addonId, this.addon, {force: true}).then(response => {
                    this.$reloadCard('trip-addon', {addonId: this.props.addonId});
                    this.$reloadCard('trip-addons', {tripId: this.addon.trip_id});
                    this.$reloadCard('addons-list', {tripId: this.addon.trip_id});
                    this.$reloadCard('trip', {tripId: this.addon.trip_id});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteAddon() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);
                    http.delete('/api/addons/' + this.props.addonId).then(response => {
                        this.$reloadCard('trip-addon', {addonId: this.props.addonId});
                        this.$reloadCard('trip-addons', {tripId: this.addon.trip_id});
                        this.$reloadCard('addons-list', {tripId: this.addon.trip_id});
                        this.$reloadCard('trip', {tripId: this.addon.trip_id});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.addonId': function () {
                this.dirty = null;
                this.loadAddon();
            },
            'addon': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this add-on? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadAddon();
        }
    };
</script>

<style lang="scss" scoped>

</style>
